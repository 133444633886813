<template>
  <TTView>
    <VRow>
      <VCol>
        <AssessmentCard :assessment="assessment" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AssessmentCard from '../../components/expert-assessment/AssessmentCard.vue';

export default {
  name: 'AssessmentView',

  components: {
    AssessmentCard,
  },

  data() {
    return {
      loading: false,
      assessment: {
        name: null,
        description: null,
        skillId: null,
        config: {
          cooldown: 0,
        },
      },
    };
  },

  computed: {
    assessmentId() {
      return this.$route.params.assessmentId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { assessmentId } = this;
        const data = { id: assessmentId };
        const { skillAssessment } = await this.$di.api.ExpertAssessment.skillAssessmentGet({ data });

        this.assessment = skillAssessment;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
